<template>

  <vs-chip class="ag-grid-cell-chip" :color="chipColor(pourcentageTransfert)">
    <span>{{ pourcentageTransfert }}%</span>
  </vs-chip>
    <!-- <vs-progress  :height="20" :percent="pourcentageTransfert" :color="chipColor(pourcentageTransfert)">
      {{pourcentageTransfert}}%
    </vs-progress> -->
</template>

<script>
export default {
  name: 'CellRendererTransfert',
  props: ['details'],
  computed: {
    pourcentageTransfert () {
      const details = this.details
      const nbreItem = details.length
      const transfertItem = details.filter((i) => i.StockTransfere === true)

      return transfertItem.length ?  ((transfertItem.length * 100) / nbreItem) : 0
    },
    chipColor () {
      return (value) => {
        if (value >= 50 && value <= 100) return 'success'
        else if (value >= 0 && value <= 25) return 'danger'
        else if (value > 25 && value < 50) return 'warning'
        else return 'primary'
      }
    }
  },
  methods: {
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.getOfficines()
  }
}
</script>
<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
